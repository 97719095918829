import { type FC } from 'react';
import { PopupModal } from 'react-calendly';

const CalendlyModal: FC<{
	firstName: string;
	lastName: string;
	email: string;
	open: boolean;
	setOpen: (open: boolean) => void;
	link: string;
}> = ({ firstName, lastName, email, open, setOpen, link }) => {
	const prefillData = {
		firstName,
		lastName,
		name: `${firstName} ${lastName}`,
		email,
	};

	return (
		<PopupModal
			url={link}
			rootElement={document.getElementById('root')!}
			prefill={prefillData}
			onModalClose={() => setOpen(false)}
			open={open}
		/>
	);
};

export default CalendlyModal;
