import { useCallback, useEffect, useRef, useState } from 'react';
import { ProfileComponentParams } from '../types';
import {
	registerCandidateInterest,
	getProfileForCandidate,
	ConciergeInterestRequestNegativeFeedback,
	logFeedback,
	ConciergeInterestRequestStatus,
	FetchProfileResponseModel,
} from '../../../api/profile';

const useCandidateRequestViewModel = ({ token }: { token: string }): ProfileComponentParams => {
	const [calendlyModalOpen, setCalendlyModalOpen] = useState(false);
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState<FetchProfileResponseModel>();
	const requestStatus = useRef<ConciergeInterestRequestStatus>();
	const [reloadData, setReloadData] = useState(true);

	const fetchProfile = useCallback(async () => {
		setLoading(true);
		const response = await getProfileForCandidate(token);
		if (response) {
			setData(response);
			if (!requestStatus.current) {
				requestStatus.current = response.metadata.status;
			}
		}
		setLoading(false);
	}, [setLoading, token]);

	useEffect(() => {
		if (
			requestStatus.current === ConciergeInterestRequestStatus.OPEN &&
			data?.metadata.status === ConciergeInterestRequestStatus.INTERESTED
		) {
			setTimeout(() => setCalendlyModalOpen(true), 500);
		}

		if (data?.metadata.status) {
			requestStatus.current = data.metadata.status;
		}
	}, [data]);

	useEffect(() => {
		if (reloadData) {
			fetchProfile();
			setReloadData(false);
		}
	}, [reloadData, fetchProfile]);

	const registerInterestInternal = useCallback(
		async (interested: boolean) => {
			await registerCandidateInterest(token, interested);
			setReloadData(true);
		},
		[token]
	);

	const logCandidateNegativeFeedback = useCallback(
		async (reasons: (ConciergeInterestRequestNegativeFeedback | string)[]) => {
			await logFeedback(token, ConciergeInterestRequestStatus.NOT_INTERESTED, reasons);
			setReloadData(true);
		},
		[token]
	);

	const params: ProfileComponentParams = {
		loading,
		data,
		registerInterest: registerInterestInternal,
		logNegativeFeedback: logCandidateNegativeFeedback,
		calendlyModalProps: {
			link: data?.metadata?.vettingCallLink ?? 'https://calendly.com/lox-club/intro-call-leena',
			open: calendlyModalOpen,
			setOpen: (open: boolean) => setCalendlyModalOpen(open),
			firstName: data?.metadata.candidateCalendlyInfo?.firstName ?? '',
			lastName: data?.metadata.candidateCalendlyInfo?.lastName ?? '',
			email: data?.metadata.candidateCalendlyInfo?.email ?? '',
		},
	};

	return params;
};

export default useCandidateRequestViewModel;
